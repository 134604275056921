export default {
    app: {
        title: 'řèƨïδèñƭ ƥôřƭáℓ',
        welcome: 'Wèℓçô₥è βáçƙ',
        logout_menu: '£ôϱôúƭ',

        ok_action: 'ôƙ',
        save_action: 'ƨáƲè',
        cancel_action: 'çáñçèℓ',
        back_action: 'βáçƙ',
        next_step_action: 'ñèжƭ ƨƭèƥ',
        retry_action: 'řè-ƭř¥',
        delete_action: 'δèℓèƭè',

        content_loading: 'ℓôáδïñϱ...',

        yes: '¥èƨ',
        no: 'ñô',

        add: 'áδδ',

        system: '§¥ƨƭè₥',

        hello: 'λèℓℓô!',
        sorry: 'ƨôřř¥!',
        start: 'ƨƭářƭ',
    },

    validation: {
        required: 'Rè9úïřèδ',
        email: 'ÌñƲáℓïδ è₥áïℓ áδδřèƨƨ',
        max_length: 'Múƨƭ βè {max} çλářáçƭèřƨ ôř ℓèƨƨ',
        min_length: 'Múƨƭ βè {min} çλářáçƭèřƨ ôř ₥ôřè',
        max_attachments_number: 'Múƨƭ βè {max} áƭƭáçλ₥èñƭƨ ôř ℓèƨƨ',
        min_attachments_number: 'Múƨƭ βè {min} áƭƭáçλ₥èñƭƨ ôř ₥ôřè',
        alphanumeric: 'Óñℓ¥ áℓƥλáñú₥èřïç çλářáçƭèřƨ',
        phone: 'ÌñƲáℓïδ ƥλôñè ñú₥βèř',
        us_phone: "Þλôñè ñú₥βèř ₥úƨƭ βè 11 δïϱïƭƨ áñδ ƨƭářƭ ωïƭλ '+' ƨïϱñ",
        no_spaces: 'Fïèℓδ çáññôƭ çôñƭáïñ ƨƥáçèƨ',
        selected: 'Âƭ ℓèáƨƭ ôñè ƨλôúℓδ βè ƨèℓèçƭèδ',
        min_value: 'Mïñï₥ú₥ Ʋáℓúè ïƨ {min}',
        max_value: 'Máжï₥ú₥ Ʋáℓúè ïƨ {max}',
    },

    calendar: {
        months: ['Jáñúář¥', 'Fèβřúář¥', 'Mářçλ', 'Âƥřïℓ', 'Má¥', 'Júñè', 'Júℓ¥', 'Âúϱúƨƭ', '§èƥƭè₥βèř', 'Óçƭôβèř', 'NôƲè₥βèř', 'Ðèçè₥βèř'],
        months_short: ['Jáñ', 'Fèβ', 'Mář', 'Âƥř', 'Má¥', 'Júñ', 'Júℓ', 'Âúϱ', '§èƥ', 'Óçƭ', 'NôƲ', 'Ðèç'],
        days_short: ['§úñ', 'Môñ', 'Túè', 'Wèδ', 'Tλú', 'Fřï', '§áƭ'],
    },

    select: {
        select: 'ƨèℓèçƭ',
        reset_all: 'Rèƨèƭ áℓℓ',
        apply: 'Âƥƥℓ¥',
        change: 'çλáñϱè',
        selected: '{count} {itemType} ƨèℓèçƭèδ',
        search: 'ƨèářçλ',
        no_results: 'Nô řèƨúℓƭƨ.',
        item: 'ïƭè₥ | ïƭè₥ƨ',
    },

    auth: {
        username: 'è₥áïℓ ôř ƥλôñè ñú₥βèř',
        password: 'ƥáƨƨωôřδ',
        new_password: 'ñèω ƥáƨƨωôřδ',

        forgot_password: 'ƒôřϱôƭ ƥáƨƨωôřδ?',
        sign_in_action: 'ƨïϱñ ïñ',
        sign_in_link: 'βáçƙ ƭô ƨïϱñ ïñ ƒôř₥',

        logout_error: 'èřřôř ƨïϱñïñϱ ôúƭ: ',

        forgot: {
            step1_title: 'ƒôřϱôƭ ¥ôúř ƥáƨƨωôřδ?',
            step1_message: 'Éñƭèř ¥ôúř É₥áïℓ βèℓôω áñδ ωè ωïℓℓ ƨèñδ á ₥èƨƨáϱè ƭô řèƨèƭ ¥ôúř ƥáƨƨωôřδ',

            step2_title: 'řèƨèƭ ¥ôúř ƥáƨƨωôřδ',
            step2_message:
                "Wè'Ʋè ƨèñƭ ¥ôú áñ è₥áïℓ ωïƭλ Ʋèřïƒïçáƭïôñ çôδè ƭô řèƨèƭ ¥ôúř ƥáƨƨωôřδ. Þℓèáƨè èñƭèř ¥ôúř Ʋèřïƒïçáƭïôñ çôδè áñδ ñèω ƥáƨƨωôřδ",

            reset_password_action: 'řèƨèƭ ₥¥ ƥáƨƨωôřδ',

            success_message: 'Þáƨƨωôřδ λáƨ βèèñ řèƨèƭ',
            user_not_found_error: 'Ûƨèř ωïƭλ ƭλáƭ è₥áïℓ δôèƨ ñôƭ èжïƨƭ',
        },

        require_new_password: {
            title: 'ñèω ƥáƨƨωôřδ řè9úïřèδ',
            change_action: 'çλáñϱè',
        },

        change_password: {
            title: 'çλáñϱè ƥáƨƨωôřδ',
            old_password: 'ôℓδ ƥáƨƨωôřδ',
            change_action: 'çλáñϱè',
            success: 'ƥáƨƨωôřδ λáƨ βèèñ ƨúççèƨƨƒúℓℓ¥ çλáñϱèδ',
        },
    },

    dashboard: {
        menu: 'Hô₥è',
        title: 'řèƨïδèñƭ ƥôřƭáℓ',
    },

    payments: {
        list: {
            menu: 'ƥá¥₥èñƭƨ',
            title: 'ƥá¥₥èñƭƨ',
            dummy_text: 'Tλïƨ ïƨ ƭλè ƥá¥₥èñƭƨ ℓïƨƭïñϱ.',
        },
        create: {
            menu: 'Máƙè ƥá¥₥èñƭ',
            title: '₥áƙè ƥá¥₥èñƭ',
            dummy_text: 'Tλïƨ ïƨ ƭλè ₥áƙè ƥá¥₥èñƭ ƥáϱè.',
            edit_link: 'Éδïƭ ƥá¥₥èñƭ δèƭáïℓƨ',
        },
        edit: {
            menu: 'Éδïƭ ƥá¥₥èñƭ δèƭáïℓƨ',
            title: 'èδïƭ ƥá¥₥èñƭ δèƭáïℓƨ',
            dummy_text: 'Éδïƭ ƥá¥₥èñƭ δèƭáïℓƨ λèřè',
        },
    },

    profile: {
        menu: 'M¥ ƥřôƒïℓè',
        title: '₥¥ ƥřôƒïℓè',
        language: 'ℓáñϱúáϱè',
        change_password: 'çλáñϱè ƥáƨƨωôřδ',
    },

    sreq: {
        list: {
            menu: '§èřƲïçè Rè9úèƨƭƨ',
            title: 'ƨèřƲïçè řè9úèƨƭƨ',
            status_groups: {
                opened: 'ôƥèñèδ',
                to_be_approved: 'ƭô βè áƥƥřôƲèδ',
                resolved: 'řèƨôℓƲèδ',
            },
            other_location: 'Óƭλèř',
            is_empty: 'Ýôú δôñ’ƭ λáƲè áñ¥ řè9úèƨƭƨ ¥èƭ',
            autocompletion_notice:
                'Ìñ çáƨè ¥ôú δôñ’ƭ Âççèƥƭ/Ðèçℓïñè ƭλè řè9úèƨƭƨ ïñ ƭλè ℓïƨƭ, ƭλè¥ ωïℓℓ βè ƭúřñèδ ïñƭô Çô₥ƥℓèƭèδ ƨƭáƭúƨ áñδ çáñ’ƭ βè řèôƥèñèδ.',
            approve_within: 'áƥƥřôƲè ωïƭλïñ',
            staff_approval_notice: 'çáñ βè ƭúřñèδ ïñƭô çô₥ƥℓèƭèδ áñ¥ ƭï₥è β¥ çô₥₥úñïƭ¥ úƨèř',
        },

        create: {
            menu: 'Nèω §èřƲïçè Rè9úèƨƭ',
            title: 'ñèω ƨèřƲïçè řè9úèƨƭ',
            submit_action: 'ƨúβ₥ïƭ řè9úèƨƭ',
            success: 'řè9úèƨƭ λáƨ βèèñ ƨúççèƨƨƒúℓℓ¥ ƨúβ₥ïƭƭèδ',
        },

        update: {
            success: {
                general: 'řè9úèƨƭ λáƨ βèèñ ƨúççèƨƨƒúℓℓ¥ úƥδáƭèδ',
                cancel: 'řè9úèƨƭ λáƨ βèèñ ƨúççèƨƨƒúℓℓ¥ çáñçèℓèδ',
                decline: 'ƭλè ωôřƙ δôñè úñδèř ƭλè řè9úèƨƭ λáƨ βèèñ δèçℓïñèδ',
                accept: 'ƭλè ωôřƙ δôñè úñδèř ƭλè řè9úèƨƭ λáƨ βèèñ ƨúççèƨƨƒúℓℓ¥ áƥƥřôƲèδ',
            },
        },

        details: {
            title: 'δèƭáïℓƨ',
        },

        time_in_out_logs: {
            title: 'ƭï₥è-ïñ / ƭï₥è-ôúƭ ℓôϱ',
            time_in: 'Tï₥è-ïñ',
            time_out: 'Tï₥è-ôúƭ',
            committed_by: 'çô₥₥ïƭƭèδ β¥',
            empty_placeholder: 'Tλè ℓôϱ ïƨ è₥ƥƭ¥',
            not_specified_yet: 'Nôƭ ƨƥèçïƒïèδ ¥èƭ',
        },

        feed: {
            title: 'ƒèèδ',
            all: 'áℓℓ',
            chat: 'çλáƭ',
            notes: 'ñôƭèƨ',
            history: 'λïƨƭôř¥',
            ordinary_note: 'ôřδïñář¥ ñôƭè',
            cost_allocation_note: 'çôƨƭ áℓℓôçáƭïôñ ñôƭè',
            recurring_payment: 'řèçúřřïñϱ ƥá¥₥èñƭ',
            one_time_payment: 'ôñè-ƭï₥è ƥá¥₥èñƭ',
            start_date: 'ƨƭářƭ δáƭè',
            due_date: 'δúè δáƭè',
            total_cost: 'ƭôƭáℓ çôƨƭ',
            months_to_repay: '№ ôƒ ₥ôñƭλƨ ƭô řèƥá¥',
            unread_messages: 'úñřèáδ ₥èƨƨáϱèƨ',
            messaging_disabled: '§èñδïñϱ ñôƭèƨ áñδ ₥èƨƨáϱèƨ ïƨñ’ƭ áƲáïℓáβℓè áƨ ƭλè řè9úèƨƭ ïƨ řèƨôℓƲèδ',
            composer_placeholder: {
                text: 'T¥ƥè á ñèω {0} ôř {1} λèřè',
                0: '₥èƨƨáϱè',
                1: 'ñôƭè',
            },
            message: '₥èƨƨáϱè',
            note: 'ñôƭè',
        },

        status: {
            NEW: 'RèçèïƲèδ',
            ASSIGNED: 'Âƨƨïϱñèδ',
            SCHEDULED: '§çλèδúℓèδ',
            WIP: 'Ìñ Þřôϱřèƨƨ',
            PM_VALIDATION: 'Wáïƭïñϱ ÞM Váℓïδáƭïôñ',
            RESIDENT_VALIDATION: 'Wáïƭïñϱ Rèƨïδèñƭ Váℓïδáƭïôñ',
            COMPLETED: 'Çô₥ƥℓèƭèδ',
            CANCELED: 'Çáñçèℓèδ',
        },

        location: {
            title: 'ωλèřè ïƨ ƭλè ƥřôβℓè₥?',
            in_my_unit: 'ïñ ₥¥ úñïƭ',
            somewhere_else: 'ƨô₥èωλèřè èℓƨè',
            building: 'βúïℓδïñϱ',
            unit: 'úñïƭ',
            apartment: 'áƥářƭ₥èñƭ',
            other_location: 'ôƭλèř ℓôçáƭïôñ',
            other_level: 'ôƭλèř ℓèƲèℓ',
            other: 'ôƭλèř',
            location_level: 'ℓôçáƭïôñ ℓèƲèℓ {n}',
            level: 'ℓèƲèℓ {n}',
        },

        fields: {
            title: 'ϱïƲè ¥ôúř řè9úèƨƭ á ƭïƭℓè',
            description: 'èжƥℓáïñ ƭλè řè9úèƨƭ ïñ ₥ôřè δèƭáïℓ',
            status: 'ƨƭáƭúƨ',
            completed_by: 'çô₥ƥℓèƭèδ β¥',
            canceled_by: 'çáñçèℓèδ β¥',
            completion_date: 'çô₥ƥℓèƭïôñ δáƭè',
            cancellation_date: 'çáñçèℓáƭïôñ δáƭè',
            submitted_by: 'ƨúβ₥ïƭƭèδ β¥',
            submitted_date: 'ƨúβ₥ïƭƭèδ δáƭè',
            assigned_to: 'áƨƨïϱñèδ ƭô',
            scheduled_date: 'ƨçλèδúℓèδ δáƭè',
            attachments: 'áƭƭáçλ₥èñƭƨ',
            alarm_code: 'áℓář₥ çôδè',
        },

        enter_permission: {
            title: 'èñƭèř ƥèř₥ïƨƨïôñ',
            description:
                'δô ¥ôú ϱřáñƭ ƥèř₥ïƨƨïôñ ƒôř á ƨèřƲïçè ƭèçλñïçïáñ ƭô èñƭèř ¥ôúř áƥářƭ₥èñƭ δúřïñϱ ƭλè ƨçλèδúℓèδ áƥƥôïñƭ₥èñƭ ƭï₥è ωλèƭλèř ¥ôú ářè ƥřèƨèñƭ ôř ñôƭ?',
            notice: 'Þℓèáƨè çřáƭè ¥ôúř ƥèƭƨ δúřïñϱ ƭλè ƨèřƲïçè áƥƥôïñƭ₥èñƭ',
        },

        notifications: {
            preferred_title: 'ƥřèƒèřřèδ çôñƭáçƭ ₥èƭλôδ',
            email: 'è₥áïℓ:',
            email_value: 'è₥áïℓ: {value}',
            text: 'ƭèжƭ:',
            text_value: 'ƭèжƭ: {value}',
            additional_title: 'áδδïƭïôñáℓ ñôƭïƒïçáƭïôñ ₥èƭλôδ',
            additional_description: 'Ìñ áδδïƭïôñ ƭô ƭλè Rèƨïδèñƭ Þôřƭáℓ, ¥ôú áℓƨô řèçèïƲè ñôƭïƒïçáƭïôñƨ ƭô:',
            same_as_preferred: '§á₥è áƨ Þřèƒèřřèδ çôñƭáçƭ ₥èƭλôδ',
            events_title: 'èƲèñƭ ñôƭïƒïçáƭïôñƨ',
            status_change: 'ƨƭáƭúƨ çλáñϱè',
            new_chat_message: 'ñèω çλáƭ ₥èƨƨáϱè',
        },

        confirmation: {
            leave: {
                text: 'ářè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô çáñçèℓ řè9úèƨƭ çřèáƭïôñ? Tλè δáƭá ωôñ’ƭ βè ƨƭôřèδ.',
                cancel_caption: 'ϱô βáçƙ ƭô řè9úèƨƭ',
            },

            cancel: {
                action: 'çáñçèℓ řè9úèƨƭ',
                title: 'Çáñçèℓ řè9úèƨƭ',
                text: 'Âřè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô çáñçèℓ ƭλè řè9úèƨƭ? Ýôú ωôñ’ƭ βè áβℓè ƭô řèôƥèñ ïƭ.',
                cancel_caption: 'Çℓôƨè',
                confirm_caption: 'Çáñçèℓ řè9úèƨƭ',
            },

            decline: {
                action: 'δèçℓïñè',
                title: 'Ðèçℓïñè ωôřƙ',
                text: 'Âřè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô δèçℓïñè ƭλè ωôřƙ δôñè úñδèř ƭλè řè9úèƨƭ? Tλè áçƭïôñ çáñ’ƭ βè úñδôñè.',
                cancel_caption: 'Çáñçèℓ',
                confirm_caption: 'Ðèçℓïñè ωôřƙ',
            },

            approve: {
                description: 'Tλè řè9úèƨƭ řè9úïřèƨ ¥ôúř çôñƒïř₥áƭïôñ ƒôř çô₥ƥℓèƭïôñ',
                action: 'áççèƥƭ',
                title: 'Âççèƥƭ ωôřƙ',
                text: 'Âřè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô áççèƥƭ ƭλè ωôřƙ δôñè úñδèř ƭλè řè9úèƨƭ? Tλè áçƭïôñ çáñ’ƭ βè úñδôñè.',
                cancel_caption: 'Çáñçèℓ',
                confirm_caption: 'Âççèƥƭ ωôřƙ',
            },
        },
    },

    messages: {
        list: {
            menu: 'Mèƨƨáϱèƨ',
            title: 'ñôƭïƒïçáƭïôñ çèñƭèř',
            is_empty: "Ýôú δôñ'ƭ λáƲè áñ¥ ₥èƨƨáϱèƨ ¥èƭ",
        },

        details: {
            title: 'δèƭáïℓƨ',
        },

        feed: {
            title: 'çλáƭ',
        },

        actions: {
            archive: 'ářçλïƲè',
            mark_as_read: '₥ářƙ áƨ řèáδ',
            mark_as_unread: '₥ářƙ áƨ úñřèáδ',
            restore: 'řèƨƭôřè',
        },
    },

    unit: {
        menu: 'M¥ úñïƭ',
        title: '₥¥ úñïƭ',
        dummy_text: 'Tλïƨ ïƨ ƭλè úñïƭ ƥáϱè.',
    },

    attachments: {
        attachments: 'áƭƭáçλ₥èñƭƨ',
        upload_failed: 'Fáïℓèδ ƭô úƥℓôáδ áƭƭáçλ₥èñƭƨ',
        see_all: 'ƨèè áℓℓ ({num})',
        add_new: 'áδδ ñèω',
        field: {
            clear_all_new: 'çℓèář áℓℓ ñèω ƒïℓèƨ',
            new_files_count: '{num} ñèω ƒïℓèƨ',
            old_files_count: '{num} ƒïℓèƨ',
        },
    },

    ri: {
        title: 'Rèñƭáβℓè Ìƭè₥ƨ',
        list: {
            title: 'Âℓℓ ïƭè₥ƨ',
            menu: 'Rèñƭáβℓè Ìƭè₥ƨ',
            is_empty: 'Tλèřè ářè ñô řèñƭáβℓè ïƭè₥ƨ ¥èƭ',
        },
        availability: {
            leased: '£èáƨèδ',
            available: '{num} áƲáïℓáβℓè ñôω',
        },
        details: {
            title: 'Rèñƭáβℓè ïƭè₥',
            working_hours: 'Wôřƙïñϱ λôúřƨ',
            closed: 'Çℓôƨèδ',
            create_reservation: 'Máƙè á ñèω řèƨèřƲáƭïôñ',
        },
        reservations: {
            title: 'M¥ řèƨèřƲáƭïôñƨ',
            create: {
                title: 'ñèω řèƨèřƲáƭïôñ',
                to_be_paid: 'Tô βè ƥáïδ',
                save_reservation: '§áƲè řèƨèřƲáƭïôñ',
                search_by_item: '§èářçλ β¥ {riType} ñá₥è',
                invalid_start_date: '{date} ïƨñ’ƭ áƲáïℓáβℓè áñ¥ ₥ôřè. Þℓèáƨè ƨèℓèçƭ ñèω ƨƭářƭ δá¥ ℓôřè₥ ïƥƨú₥ δôℓ',
                invalid_items:
                    '{unavailable} ƒřô₥ {total} {riType} ƨèℓèçƭèδ ïƨñ’ƭ áƲáïℓáβℓè áñ¥ ₥ôřè | {unavailable} ƒřô₥ {total} {riType} ƨèℓèçƭèδ ářèñ’ƭ áƲáïℓáβℓè áñ¥ ₥ôřè',
                invalid_start_datetime: '{datetime} ïƨñ’ƭ áƲáïℓáβℓè áñ¥ ₥ôřè. Þℓèáƨè ƨèℓèçƭ ñèω ƨƭářƭ ƭï₥è',
                restricted_duration: '§èℓèçƭèδ δúřáƭïôñ ïƨ řèƨƭřïçƭèδ δúè ƭô áƲáïℓáβïℓïƭ¥ ƨçλèδúℓè',
                reduced_reservation_period:
                    'Þℓèáƨè, ñôƭè ƭλáƭ ¥ôúř řèƨèřƲáƭïôñ ƥèřïôδ ïƨ ℓèƨƨ ƭλáñ {period} δúè ƭô áƲáïℓáβïℓïƭ¥ ƨçλèδúℓè ôƒ ƨèℓèçƭèδ {selectedRiType}. {riType} ƨλôúℓδ βè řèƭúřñèδ βèƒôřè çô₥₥úñïƭ¥ çℓôƨèƨ δúřïñϱ ƭλè ℓáƨƭ δá¥. Tô řèƭúřñ ïƭ ℓáƭèř - ƨèƭ áñôƭλèř řèƨèřƲáƭïôñ ƥèřïôδ',
            },
            list: {
                status_groups: {
                    actual: 'áçƭúáℓ',
                    active: 'áçƭïƲè',
                    completed: 'çô₥ƥℓèƭèδ',
                    canceled: 'çáñçèℓèδ',
                    pending: 'ƥèñδïñϱ',
                },
                is_empty: 'Ýôú δôñ’ƭ λáƲè řèƨèřƲáƭïôñƨ ¥èƭ',
            },
            status: {
                PENDING: 'Þèñδïñϱ',
                ACTIVE: 'ÂçƭïƲè',
                COMPLETED: 'Çô₥ƥℓèƭèδ',
                CANCELED: 'Çáñçèℓèδ',
            },
            details: {
                title: 'řèƨèřƲáƭïôñ δèƭáïℓƨ',
            },
            confirmation: {
                create: {
                    text: 'ářè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô ℓèáƲè ƭλïƨ ƨçřèèñ ωïƭλôúƭ ƨáƲïñϱ?',
                    cancel_caption: 'Nô, ϱô βáçƙ',
                    confirm_caption: 'Ýèƨ, ℓèáƲè',
                },
                cancel: {
                    action: 'Çáñçèℓ řèƨèřƲáƭïôñ',
                    text: 'ářè ¥ôú ƨúřè ¥ôú ωáñƭ ƭô çáñçèℓ ƭλè řèƨèřƲáƭïôñ?',
                    cancel_caption: 'Nô, ϱô βáçƙ',
                    confirm_caption: 'Ýèƨ, çáñçèℓ',
                },
            },
            fields: {
                item_name: '{riType} ñá₥è',
                timeslot: 'řáƭè ƥèř ₥ïñ. ƭï₥è ƨℓôƭ',
                start_date: 'ƨƭářƭ δáƭè',
                end_date: 'èñδ δáƭè',
                created_by: 'çřèáƭèδ β¥',
                canceled_by: 'çáñçèℓèδ β¥',
                entire_cost: 'çôƨƭ ƒôř ƭλè èñƭïřè ƥèřïôδ',
                status: 'ƨƭáƭúƨ',
                from: 'ƒřô₥',
                to: 'ƭô',
                cancellation_date: 'çáñçèℓℓáƭïôñ δáƭè',
                number_of_items: 'ñú₥βèř ôƒ áƨƨèƭƨ',
                duration: 'δúřáƭïôñ (₥ôñƭλƨ)',
                available: 'áƲáïℓáβℓè',
                not_specified: 'Nôƭ ƨƥèçïƒïèδ',
                items: '§èℓèçƭ {riType}',
                select_time: '§èℓèçƭ ƨƭářƭ ƭï₥è',
            },
            item_deleted: 'ωáƨ δèℓèƭèδ | ωèřè δèℓèƭèδ',
            messages: {
                cancel: {
                    item_deleted: 'Tλè řèƨèřƲáƭïôñ ωáƨ çáñçèℓèδ βèçáúƨè ƭλè {riType} {deleted} β¥ Çô₥₥úñïƭ¥',
                    ri_deleted: 'Tλè řèƨèřƲáƭïôñ ωáƨ çáñçèℓèδ βèçáúƨè ƭλè řèñƭáβℓè ïƭè₥ ωáƨ δèℓèƭèδ β¥ Çô₥₥úñïƭ¥',
                },
                create: {
                    success: 'RèƨèřƲáƭïôñ ωáƨ ƨúççèƨƨƒúℓℓ¥ ƨáƲèδ. | {reservationsCount} řèƨèřƲáƭïôñƨ ωèřè ƨúççèƨƨƒúℓℓ¥ ƨáƲèδ.',
                    payment: 'Tô ƥá¥ ƒôř ïƭ, ƥℓèáƨè ƥřôçèèδ ƭô ƭλè',
                    resident_ledger: 'řèƨïδèñƭ ℓèδϱèř',
                    info: 'Ýôú çáñ ƨƭářƭ ƭô ƨèℓèçƭ {riType}, ƭλèñ ƭλè çáℓèñδář ωïℓℓ δïƨƥℓá¥ ƭλè çôřřèƨƥôñδïñϱ áƲáïℓáβℓè δáƭèƨ. Âñδ Ʋïçè Ʋèřƨá',
                },
            },
        },
        item: 'áƨƨèƭ | áƨƨèƭƨ',
        location: 'ℓôçáƭïôñ | ℓôçáƭïôñƨ',
        periods: {
            hour: 'λôúř | λôúřƨ',
            day: 'δá¥ | δá¥ƨ',
            week: 'ωèèƙ | ωèèƙƨ',
            month: '₥ôñƭλ | ₥ôñƭλƨ',
        },
    },

    pass: {
        welcome: 'Wèℓçô₥è!',
        pass_starts_at: 'Tλè ƥáƨƨ ωïℓℓ ωôřƙ áƒƭèř {date}',
        pass_failed: 'Tλè ƥáƨƨ ïƨ ñô ℓôñϱèř áƲáïℓáβℓè. Þℓèáƨè, çôñƭáçƭ ¥ôúř λôƨƭ',
        something_went_wrong: '§ô₥èƭλïñϱ ωèñƭ ωřôñϱ',
        use_host_secret: 'úƨè λôƨƭ ƨèçřèƭ',
        host_secret_notice: 'Tô úƨè ƭλè Þáƨƨ, ƥℓèáƨè èñƭèř ƭλè ƨèçřèƭ çôδè ƭλáƭ ƭλè Hôƨƭ ϱáƲè ¥ôú.',
        use_verification_code: 'úƨè Ʋèřïƒïçáƭïôñ çôδè',
        otp_sent_notice: 'ÓTÞ λáƨ βèèñ ƨèñƭ ƭô ¥ôúř ₥ôβïℓè ñú₥βèř. Þℓèáƨè Ʋèřïƒ¥',
        host_secret: 'λôƨƭ ƨèçřèƭ',
        otp: 'ÓTÞ',
        verify: 'Ʋèřïƒ¥',
        resend_in: 'řèƨèñδ | řèƨèñδ ïñ {count}s',
        otp_verification_failed_notice:
            'Tλè ÓTÞ ïƨ ωřôñϱ. Ýôú λáƲè ñô ƭřïèƨ ℓèƒƭ | Tλè ÓTÞ ïƨ ωřôñϱ. Ýôú λáƲè ƭλè ℓáƨƭ ƭř¥. Tř¥ áϱáïñ | Tλè ÓTÞ ïƨ ωřôñϱ. Ýôú λáƲè ôƭλèř {count} ƭřïèƨ. Tř¥ áϱáïñ',
        host_secret_verification_failed_notice: 'Tλè λôƨƭ ƨèçřèƭ ïƨ ωřôñϱ',
        your_pass_from: '¥ôúř ƥáƨƨ from {name}',
        access_to: 'áççèƨƨ ƭô',
        n_locations: '1 ℓôçáƭïôñ | {count} ℓôçáƭïôñƨ',
        expiration_date: 'èжƥïřáƭïôñ δáƭè',
        due_date: 'δúè δáƭè',
        expires_in: 'èжƥïřèƨ ïñ',
        limit_of_usage: 'ℓï₥ïƭ ôƒ úƨáϱè',
        host_left_message: 'ƭλè λôƨƭ ℓèƒƭ ¥ôú á ₥èƨƨáϱè:',
        hours_short: 'λ',
        minutes_short: '₥ïñ',
        seconds_short: 'ƨèç',
        tap_to_unlock: 'Táƥ ƭô úñℓôçƙ',
        sending_request: 'Wè ářè ƨèñδïñϱ řè9úèƨƭ...',
        authorization_sent: 'Âúƭλôřïƺáƭïôñ ƨèñƭ',
        attempts_max_reached: 'Tλè ₥áжï₥ú₥ á₥ôúñƭ ôƒ áƭƭè₥ƥƭƨ ïñ řèáçλèδ',
        no_verify_greeting: "Ìƭ'ƨ áℓωá¥ƨ á ƥℓèáƨúřè ƭô ƨèè ¥ôú. Tλè λôƨƭ ƥřèƥářèδ ƭλè ƥáƨƨ ƒôř ¥ôú, ƥřèƨƨ ƭλè βúƭƭôñ ƭô ƨƭářƭ úƨïñϱ ïƭ.",
        send_otp: '§èñδ ÓTÞ',
        mobile_phone: '₥ôβïℓè ƥλôñè',
        enter: 'èñƭèř',
        sms_code_header: "Wè'Ʋè ƭô ƨèñδ ¥ôú áñ §M§ çôδè",
        sms_code_notice: 'Tô çôñƨú₥ïñϱ ƭλè ƥáƨƨ, èñƭèř ¥ôúř ₥ôβïℓè ñú₥βèř áñδ úƨè ƭλè řèçèïƲèδ ÓTÞ çôδè',
    },
};
